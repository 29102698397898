import React, { Component } from "react";
import { Form, Modal, Button, FormControl, Spinner, Table } from "react-bootstrap";

class ModalFedex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDone: false,
      numberOfContainers: 1,
    };
    this.initiateModal = this.initiateModal.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  initiateModal() {
    this.setState({ isDone: false });
  }

  async submitForm(e) {
    e.preventDefault();

    const numberOfContainers = e.target.elements.numberOfContainers.value;
    const boxWeightsElements = e.target.elements["boxWeights[]"];

    let boxWeights = [];

    if (boxWeightsElements.length !== undefined && boxWeightsElements.length >= 2)
      boxWeightsElements.forEach((bwe) => {
        boxWeights.push(bwe.value);
      });
    else boxWeights.push(boxWeightsElements.value);

    //const packageWeight = e.target.elements.packageWeight.value;

    const shipperName = e.target.elements.shipperName.value;
    const shipperCompanyName = e.target.elements.shipperCompanyName.value;
    const shipperPhoneNumber = e.target.elements.shipperPhoneNumber.value;
    const shipperAddressStreet = e.target.elements.shipperAddressStreet.value;
    const shipperAddressCity = e.target.elements.shipperAddressCity.value;
    const shipperAddressStateCode = e.target.elements.shipperAddressStateCode.value;
    const shipperAddressPostalCode = e.target.elements.shipperAddressPostalCode.value;
    const shipperAddressCountryCode = "US";

    const shipmentData = {
      numberOfContainers,
      boxWeights,
      //packageWeight,
      shipperName,
      shipperCompanyName,
      shipperPhoneNumber,
      shipperAddressStreet,
      shipperAddressCity,
      shipperAddressStateCode,
      shipperAddressPostalCode,
      shipperAddressCountryCode,
    };

    this.props.bookFreightMP(shipmentData);
  }

  render() {
    return (
      <Modal
        onEnter={this.initiateModal}
        show={this.props.showModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header closeButton>
          <h3>Book freight & process shipment</h3>
        </Modal.Header>

        <Modal.Body>
          {this.props.isFedexDone === true ? (
            <div>
              <Table bordered>
                <tbody>
                  <tr>
                    <td>Tracking ID</td>
                    <td>{this.props.fedexResponse.meta.trackingId}</td>
                  </tr>
                  <tr>
                    <td>Delivery Date</td>
                    <td>{this.props.fedexResponse.meta.deliveryDate}</td>
                  </tr>
                  <tr>
                    <td>Charges</td>
                    <td>${this.props.fedexResponse.meta.packageCharges}</td>
                  </tr>
                  <tr>
                    <td>Barcode Number</td>
                    <td>{this.props.fedexResponse.meta.stringBarcode}</td>
                  </tr>

                  <tr>
                    <td colSpan={2} style={{ fontWeight: "bold", fontSize: "16px", textAlign: "center" }}>
                      <a
                        download={`receipt.pdf`}
                        href={`data:application/pdf;base64,${this.props.fedexResponse.meta.labelImage}`}
                      >
                        Download Receipt
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : (
            <Form inline onSubmit={this.submitForm}>
              <Table bordered>
                <tbody>
                  <tr>
                    <td style={{ width: "300px" }}>Number of Packages</td>
                    <td style={{ paddingLeft: "20px" }}>
                      <Form.Control
                        required
                        autoComplete="off"
                        min={1}
                        maxLength={3}
                        style={{ width: "100px" }}
                        type="number"
                        value={this.state.numberOfContainers}
                        name="numberOfContainers"
                        onChange={(e) => {
                          this.setState({ numberOfContainers: e.target.value });
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Package Weights (in lb.)</td>
                    {/* <td>
                      <FormControl
                        required
                        style={{ width: "250px", marginRight: "5px", marginBottom: "5px" }}
                        inline
                        name="packageWeight"
                        type="numbers"
                        placeholder="Weight in lb"
                      ></FormControl>
                    </td> */}
                    <td style={{ paddingLeft: "20px" }} colSpan={3}>
                      {Array.from({ length: this.state.numberOfContainers }, (_, k) => (
                        <FormControl
                          required
                          autoComplete="off"
                          style={{ width: "80px", marginRight: "5px", marginBottom: "5px" }}
                          key={k}
                          inline
                          name="boxWeights[]"
                          type="numbers"
                          placeholder={`${"Box"} ${k + 1}`}
                        ></FormControl>
                      ))}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2} style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Shipping Person & Address
                    </td>
                  </tr>
                  <tr>
                    <td>Shipper Name</td>
                    <td>
                      <Form.Control style={{ width: "100%" }} required type="text" name="shipperName" />
                    </td>
                  </tr>
                  <tr>
                    <td>Shipper Company Name</td>
                    <td>
                      <Form.Control style={{ width: "100%" }} required type="text" name="shipperCompanyName" />
                    </td>
                  </tr>
                  <tr>
                    <td>Shipper Phone Name</td>
                    <td>
                      <Form.Control style={{ width: "100%" }} required type="text" name="shipperPhoneNumber" />
                    </td>
                  </tr>
                  <tr>
                    <td>Street Address</td>
                    <td>
                      <Form.Control style={{ width: "100%" }} required type="text" name="shipperAddressStreet" />
                    </td>
                  </tr>
                  <tr>
                    <td>Shipper Address City</td>
                    <td>
                      <Form.Control required type="text" name="shipperAddressCity" />
                    </td>
                  </tr>
                  <tr>
                    <td>Shipper Address State Code (E.g. CO)</td>
                    <td>
                      <Form.Control required type="text" name="shipperAddressStateCode" />
                    </td>
                  </tr>
                  <tr>
                    <td>Shipper Address Postal Code</td>
                    <td>
                      <Form.Control required type="text" name="shipperAddressPostalCode" />
                    </td>
                  </tr>
                </tbody>
              </Table>

              {this.props.inProcessSubmitFedex === true ? (
                <Spinner animation="border" role="status"></Spinner>
              ) : (
                <Button variant="primary" type="submit">
                  Submit Batch
                </Button>
              )}
            </Form>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalFedex;
