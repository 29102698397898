import React, { Component } from "react";
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import "../assets/style/login.css";
import { submitLogin } from "../includes/HelperLogin";
import { loginPageBanner, loginPageEmail, loginPageLogo, logoWidth } from "../Config";
import auth from "../Auth";

class Login extends Component {
  state = {
    logingin: false,
    isInvalidLogin: -1,
    invalidLoginMessage: "",
    isTokenExpired: 0,
    loginPageLogo: loginPageLogo,
  };

  constructor() {
    super();
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let isTokenExpired = params.get("token-expiry");
    if (isTokenExpired !== null) this.setState({ isTokenExpired: parseInt(isTokenExpired) });
  }

  async handleLogin(event) {
    event.preventDefault();
    const user_email = event.target.elements.user_email.value;
    const user_password = event.target.elements.user_password.value;

    this.setState({ logingin: true });
    const response = await submitLogin(user_email, user_password);
    this.setState({ logingin: false });
    if (response.status === 0) {
      this.setState({ isInvalidLogin: 1, invalidLoginMessage: response.error });
    } else {
      auth.login(response, () => {
        this.props.history.push("app");
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
          <Col md={5} className="div-login-left">
            <div className="div-form-container">
              <div>
                <img
                  alt="logo"
                  className="logo"
                  style={{ width: `${logoWidth}px` }}
                  src={require(`../assets/images/${this.state.loginPageLogo}`)}
                />
              </div>

              {this.state.isInvalidLogin === 1 && (
                <div style={{ marginTop: "20px" }}>
                  <Alert variant="danger">
                    <span>{this.state.invalidLoginMessage}</span>
                  </Alert>
                </div>
              )}
              <div>
                {this.state.isTokenExpired === 1 && (
                  <Alert style={{ marginTop: "40px" }} key="auto-logout" variant="warning">
                    You have been logged out because you logged in from another device.
                  </Alert>
                )}

                <div className="welcome-msg-login">Welcome back! Please login to your account.</div>

                <Form onSubmit={this.handleLogin}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label style={{ marginBottom: "0px" }}>Email address</Form.Label>
                    <Form.Control autoFocus type="email" name="user_email" className="inputNoFormat" />
                  </Form.Group>

                  <Form.Group style={{ marginTop: "50px" }} controlId="formBasicPassword">
                    <Form.Label style={{ marginBottom: "0px" }}>Enter Password</Form.Label>
                    <Form.Control type="password" name="user_password" className="inputNoFormat" />
                  </Form.Group>

                  {this.state.logingin ? (
                    <Spinner style={{ marginTop: "33px" }} animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    <Button
                      variant="primary"
                      type="submit"
                      style={{ marginTop: "30px", borderRadius: "0", padding: "7px 25px 7px 25px" }}
                    >
                      Submit
                    </Button>
                  )}
                </Form>

                <div className="support-msg" style={{ marginTop: "20px" }}>
                  <Button
                    onClick={() => {
                      this.props.history.push("forgot-password");
                    }}
                    style={{ padding: "0", color: "#DC3545" }}
                    variant="link"
                  >
                    Forgot Password
                  </Button>
                </div>

                <div className="support-msg">For any questions, please email us at {loginPageEmail}</div>

                <div className="support-msg" style={{ marginTop: "50px" }}>
                  <Button
                    onClick={() => {
                      this.props.history.push("privacy-policy");
                    }}
                    style={{ padding: "0", color: "#353434" }}
                    variant="link"
                  >
                    Privacy Policy
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col
            md={7}
            style={{
              background: `url(${require("../assets/images/" + loginPageBanner)}) no-repeat center center`,
              backgroundSize: "cover",
              backgroundPosition: "left 0px top 0px",
            }}
          ></Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Login;
