import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import * as serviceWorker from "./serviceWorker";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Route404 from "./pages/Route404";
import "./assets/style/theme_minimal.css";
import { favicon, documentTitle } from "./Config";
import ProtectedRoute from "./ProtectedRoute";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

class App extends Component {
  state = {
    favicon: favicon,
    newVersionAvailable: false,
    waitingWorker: {},
  };

  onServiceWorkerUpdate = (registration) => {
    this.setState({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
    });
  };

  componentDidMount() {
    var faviconLocal = this.state.favicon;
    (function () {
      var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = require(`./assets/images/${faviconLocal}`);
      document.getElementsByTagName("head")[0].appendChild(link);
      document.title = documentTitle;
    })();

    // if (process.env.NODE_ENV === "production") {
    //   serviceWorker.register({
    //     onUpdate: (reg) => {
    //       alert("CODE UPDATED! Press Ctrl or Cmd + Shift + R");
    //       this.onServiceWorkerUpdate(reg);
    //     },
    //   });
    // }
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/welcome" component={Signup} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <ProtectedRoute exact path="/app" component={Home} />
            <Route component={Route404} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
